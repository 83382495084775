import React from "react";

import Layout from "../components/common/Layout";
import SEO from "../components/common/SEO";
import NewsletterRow from "../components/landingPage/NewsletterRow";

import { Linkedin, Twitter, Instagram, Facebook } from "react-feather";

import "./contact.css";

const IndexPage = () => {
  return (
    <main>
      <Layout>
        <SEO title="Kontakt - Hazay Bikes" />
        <div
          style={{
            backgroundColor: "rgb(36, 36, 36)",
            width: "100%",
            paddingTop: "80px",
          }}
        >
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: "52.5rem",
              padding: `3rem 1.3125rem 7.625rem`,
              flex: 1,
            }}
          >
            <h1 style={{ margin: 0, marginBottom: "3rem", color: "white" }}>
              Kontakt
            </h1>
            <table style={{ fontSize: "16px" }}>
              <tbody>
                <tr>
                  <th className="contact-page-table-header">
                    Marcin Grausch / CEO
                  </th>
                  <td className="contact-page-table-row">+48 509 668 294</td>
                </tr>
                <tr>
                  <th className="contact-page-table-header">
                    Kuba Garstkiewicz / Design
                  </th>
                  <td className="contact-page-table-row">+48 608 463 845</td>
                </tr>
                <tr>
                  <th className="contact-page-table-header">e-mail</th>
                  <td className="contact-page-table-row">
                    info@hazaybikes.com
                  </td>
                </tr>
                <tr>
                  <th className="contact-page-table-header">adres</th>
                  <td className="contact-page-table-row">
                    Święty Marcin 29/8 61-806, Poznań, Polska
                  </td>
                </tr>
                <tr>
                  <th className="contact-page-table-header">social</th>
                  <td className="contact-page-table-row">{socialLinks()}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <NewsletterRow hideHeading />
      </Layout>
    </main>
  );
};

const socialLinks = () => [
  <a
    href="https://www.linkedin.com/company/hazay-bikes/"
    target="_blank"
    rel="noopener noreferrer"
    className="contact-social-icon"
  >
    <Linkedin />
  </a>,
  <a
    href="https://twitter.com/BikesHazay"
    target="_blank"
    rel="noopener noreferrer"
    className="contact-social-icon"
  >
    <Twitter />
  </a>,
  <a
    href="https://www.instagram.com/hazay_bikes/"
    target="_blank"
    rel="noopener noreferrer"
    className="contact-social-icon"
  >
    <Instagram />
  </a>,
  <a
    href="https://www.facebook.com/hazaybikes"
    target="_blank"
    rel="noopener noreferrer"
    className="contact-social-icon"
  >
    <Facebook />
  </a>,
];

export default IndexPage;
